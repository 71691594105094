<template>
  <b-row>
    <b-col cols="12" sm="8">
      <b-card>
        <h4>Version History</h4>
        <h5>v1.14.2 <small>(09/05/2024)</small></h5>
        <ul>
          <li><strong>Policies:</strong> Add date field to existing policies to address requirement that policies linked to strategies needing to have start dates</li>
        </ul>
        <h5>v1.14.1 <small>(08/07/2024)</small></h5>
        <ul>
          <li><strong>Policies:</strong> Resolve a bug with future dated policies generating errant illustration records</li>
          <li><strong>Policies:</strong> Aligned new policy creation dates to default to strategy start date</li>
          <li><strong>Strategies:</strong> Resolved a cash value availability bug for future dated policies</li>
        </ul>
        <h5>v1.14.0 <small>(07/25/2024)</small></h5>
        <ul>
          <li><strong>Policies:</strong> Revamped policy handling to use start dates relative to strategy start dates</li>
          <li><strong>Policies:</strong> Improved illustration display and editing</li>
          <li><strong>Strategies:</strong> Linked policies can be prioritized (ordered) for loans and payments and can be disabled</li>
        </ul>
        <h5>v1.13.3 <small>(07/12/2023)</small></h5>
        <ul>
          <li><strong>Authentication:</strong> Resolved authentication issue with some actions causing failed actions (PDF Exporting, etc)</li>
        </ul>
        <h5>v1.13.2 <small>(06/19/2023)</small></h5>
        <ul>
          <li><strong>Strategies:</strong> Resolved secondary bug with PDF Exporting</li>
        </ul>
        <h5>v1.13.1 <small>(05/18/2023)</small></h5>
        <ul>
          <li><strong>Strategies:</strong> Resolved bug with PDF Exporting</li>
        </ul>
        <h5>v1.13.0 <small>(05/17/2023)</small></h5>
        <ul>
          <li><strong>Contacts:</strong> Multi Country Support</li>
          <li><strong>Policies:</strong> Improvements to policy saving messaging</li>
          <li><strong>Contacts:</strong> Resolved issue with contact type not being required</li>
          <li><strong>Backend:</strong> Several upgrades to backend API</li>
        </ul>
        <h5>v1.12.10 <small>(10/01/2022)</small></h5>
        <ul>
          <li><strong>Strategy:</strong> Improved tax calculation. Minor fixes.</li>
        </ul>
        <h5>v1.12.9 <small>(08/01/2022)</small></h5>
        <ul>
          <li><strong>Clients:</strong> Upgraded client data model</li>
        </ul>
        <h5>v1.12.8 <small>(06/01/2022)</small></h5>
        <ul>
          <li><strong>Accounts:</strong> Upgraded account service data model</li>
        </ul>
        <h5>v1.12.7 <small>(06/27/2022)</small></h5>
        <ul>
          <li><strong>Policies:</strong> Added pre-calculated fields for base and pua to policy data structure</li>
        </ul>
        <h5>v1.12.6 <small>(06/01/2022)</small></h5>
        <ul>
          <li><strong>Accounts:</strong> Upgraded account service data model</li>
        </ul>
        <h5>v1.12.5 <small>(05/16/2022)</small></h5>
        <ul>
          <li><strong>Client:</strong> Added province to address data structure</li>
        </ul>
        <h5>v1.12.4 <small>(03/02/2022)</small></h5>
        <ul>
          <li><strong>Client:</strong> Added country to address data structure</li>
        </ul>
        <h5>v1.12.3 <small>(01/18/2022)</small></h5>
        <ul>
          <li><strong>Strategy:</strong> Bug fix to prepaid interest calculation</li>
        </ul>
        <h5>v1.12.2 <small>(09/22/2021)</small></h5>
        <ul>
          <li><strong>Intake:</strong> Resolved minor email date display issue</li>
        </ul>
        <h5>v1.12.1 <small>(09/16/2021)</small></h5>
        <ul>
          <li><strong>Intake:</strong> Add Last Requested On date.</li>
          <li><strong>Intake Bug:</strong> Resolve issue with resending intake requests that were manually started.</li>
        </ul>
        <h5>v1.12.0 <small>(04/28/2021)</small></h5>
        <ul>
          <li><strong>Policy Design:</strong> Resolve bug with editing premiums not saving.</li>
          <li><strong>Clients:</strong> Resolve bug with duplicate client deletion.</li>
          <li><strong>Summary:</strong> Add Inputs to Summary page and Summary PDF.</li>
        </ul>
        <h5>v1.11.2 <small>(04/12/2021)</small></h5>
        <ul>
          <li><strong>Inputs:</strong> Resolve bug with syncing some inputs.</li>
        </ul>
        <h5>v1.11.1 <small>(03/23/2021)</small></h5>
        <ul>
          <li><strong>Inputs:</strong> Resolve bug where changing asset types was not triggering a need to re-sync the input.</li>
        </ul>
        <h5>v1.11.0 <small>(03/21/2021)</small></h5>
        <ul>
          <li><strong>Policy Design:</strong> Add Ability to import policy illustration (Mutual Trust Life Format)</li>
          <li><strong>Client Input:</strong> Improved display of policies.  Added Start/Term Length fields for term policies.</li>
          <li><strong>Email Templates:</strong> Removed System Invoice template</li>
          <li><strong>Tutorials:</strong> Added Email Template Tutorial</li>
        </ul>
        <h5>v1.10.4 <small>(02/24/2021)</small></h5>
        <ul>
          <li><strong>Policy Design:</strong> Resolved a bug with payment source not updating strategy when changed</li>
        </ul>
        <h5>v1.10.3 <small>(01/15/2021)</small></h5>
        <ul>
          <li><strong>Tutorials:</strong> Minor content fixes</li>
        </ul>
        <h5>v1.10.2 <small>(01/15/2021)</small></h5>
        <ul>
          <li><strong>Strategy:</strong> Improvement to windcurrent change color coding for future debts</li>
          <li><strong>Tutorials:</strong> Added Future Debt tutorials and other enhancements video</li>
        </ul>
        <h5>v1.10.1 <small>(01/03/2021)</small></h5>
        <ul>
          <li><strong>Client Input:</strong> Minor contact type fix</li>
        </ul>
        <h5>v1.10.0 <small>(12/31/2020)</small></h5>
        <ul>
          <li><strong>Intake Form:</strong> Added Other Contacts.  Added separate section for Existing Policies.</li>
          <li><strong>Client Input:</strong> Added Other Contacts</li>
          <li><strong>Strategy Input:</strong> Policy Design - Added ability to enter premiums</li>
          <li><strong>Strategy Input:</strong> Policy Design - Improvements to synching existing loan details</li>
          <li><strong>Strategy Input:</strong> Policy Design - Expanded age range</li>
          <li><strong>Strategy Input:</strong> Policy Design - Added ability to tie policies to contacts</li>
        </ul>
        <h5>v1.9.0 <small>(11/11/2020)</small></h5>
        <ul>
          <li><strong>Client Input:</strong> Added Timeframe to Debts to allow for future debt designation and payment source.</li>
          <li><strong>Client Input:</strong> Vehicles - Resolve save error if vehicle year blank</li>
          <li><strong>Strategy Input:</strong> Policy Design - Resolve calculation error when PUAR Thru Year is set to No Drop</li>
          <li><strong>Client Intake:</strong> Add email notification to coach upon intake completion.</li>
          <li><strong>Tutorials:</strong> Added Email Setting Tutorial</li>
        </ul>
        <h5>v1.8.2 <small>(09/25/2020)</small></h5>
        <ul>
          <li><strong>Dashboard:</strong> Replaced Organization column on Intake Requests with Agent Name</li>
          <li><strong>Dashboard:</strong> Added filter option on dashboard</li>
          <li><strong>Client Input:</strong> Removed Organization section (no longer necessary)</li>
        </ul>

        <h5>v1.8.1 <small>(09/23/2020)</small></h5>
        <ul>
          <li><strong>Tutorials:</strong> Added Future Debt tutorial</li>
        </ul>

        <h5>v1.8.0 <small>(09/20/2020)</small></h5>
        <ul>
          <li><strong>Client Input:</strong> Added Timeframe (Existing vs Future) to debt inputs (Mortgages, Credit Cards, Loans, Vehicles)</li>
          <li><strong>Strategies:</strong> Updated Strategy to account for future debts.  Added date display to Year/Month tables on Strategy</li>
          <li><strong>Policy Design:</strong> Updated cash value year numbers to reflect seasoning</li>
          <li><strong>Clients Section:</strong> Improved client menu structure by allowing user to more easily get back to Client Info</li>
          <li><strong>Strategy Input:</strong> Add ability to edit strategy info (name, start date and tax bracket)</li>
        </ul>

        <h5>v1.7.2 <small>(09/02/2020)</small></h5>
        <ul>
          <li>Icon and Text Updates</li>
        </ul>

        <h5>v1.7.1 <small>(08/31/2020)</small></h5>
        <ul>
          <li>Add Billing Email to Organizations</li>
          <li>Add pdf invoice option</li>
        </ul>

        <h5>v1.7.0 <small>(08/13/2020)</small></h5>
        <ul>
          <li>Added Help Center/Tutorials</li>
          <li>Added Version History</li>
        </ul>

        <h5>v1.6.0 <small>(08/1/2020)</small></h5>
        <ul>
          <li>Add PDF functionality</li>
          <li>Minor link tweaks</li>
        </ul>

        <h5>v1.5.2 <small>(07/21/2020)</small></h5>
        <ul>
          <li>Fixed payment display</li>
        </ul>

        <h5>v1.5.1 <small>(07/21/2020)</small></h5>
        <ul>
          <li>Add Policy Documents</li>
          <li>Invoice bug fix</li>
        </ul>

        <h5>v1.5.0 <small>(07/20/2020)</small></h5>
        <ul>
          <li>Add Subscription, Payment Profile, Invoicing support</li>
        </ul>

        <h5>v1.4.1 <small>(07/12/2020)</small></h5>
        <ul>
          <li>Change phone number format from dotted to dashed</li>
        </ul>

        <h5>v1.4.0 <small>(04/26/2020)</small></h5>
        <ul>
          <li>Custom email templates by org</li>
          <li>Additional account roles</li>
          <li>Improved dashboard</li>
          <li>Initial Organization specific branding</li>
        </ul>

        <h5>v1.3.7 <small>(04/20/2020)</small></h5>
        <ul>
          <li>Added API url</li>
        </ul>

        <h5>v1.3.6 <small>(04/18/2020)</small></h5>
        <ul>
          <li>Add Mark Completed to pre-intake status</li>
        </ul>

        <h5>v1.3.5 <small>(03/31/2020)</small></h5>
        <ul>
          <li>Bugfix: Always fetch client to get assets</li>
        </ul>

        <h5>v1.3.4 <small>(03/27/2020)</small></h5>
        <ul>
          <li>Minor summary tweaks</li>
        </ul>

        <h5>v1.3.3 <small>(03/26/2020)</small></h5>
        <ul>
          <li>Bugfix: Resolve issue when saving policy design</li>
        </ul>

        <h5>v1.3.2 <small>(03/26/2020)</small></h5>
        <ul>
          <li>Cleanup: Remove Debug statements</li>
          <li>List asset names when linking to mortgage</li>
        </ul>

        <h5>v1.3.1 <small>(03/26/2020)</small></h5>
        <ul>
          <li>Minor fixes</li>
        </ul>

        <h5>v1.3.0 <small>(03/26/2020)</small></h5>
        <ul>
          <li>Upated inputs</li>
          <li>Fixed Copyright Year</li>
          <li>Tweaked PDF name</li>
          <li>Update summary math for non IBC (no windcurrent)</li>
          <li>Minor existing policy linking update</li>
        </ul>

        <h5>v1.2.2 <small>(03/24/2020)</small></h5>
        <ul>
          <li>Fix another secondary birthday display issue</li>
        </ul>

        <h5>v1.2.1 <small>(03/23/2020)</small></h5>
        <ul>
          <li>Fix secondary birthday display</li>
        </ul>

        <h5>v1.2.0 <small>(03/22/2020)</small></h5>
        <ul>
          <li>Resolve issue with summary</li>
        </ul>

        <h5>v1.1.0 <small>(03/20/2020)</small></h5>
        <ul>
          <li>Resolve issue with cash value sorting issue #15</li>
          <li>Client deduplicate</li>
          <li>Summary PDF and summary improvement</li>
          <li><strong>Client Info:</strong> Birthday on client page</li>
          <li><strong>Client Info:</strong> Display coach who submitted intake request</li>
          <li><strong>Bug fix:</strong>Resolve issues with jumbled years on policy design</li>
        </ul>

        <h5>v1.0.4 <small>(11/09/2019)</small></h5>
        <ul>
          <li><strong>Bug fix:</strong> Linking existing policies modal</li>
        </ul>

        <h5>v1.0.3 <small>(10/17/2019)</small></h5>
        <ul>
          <li><strong>Bug fix:</strong> Mortgage Saving Issue</li>
        </ul>

        <h5>v1.0.2 <small>(10/16/2019)</small></h5>
        <ul>
          <li>Update strategy/summary</li>
        </ul>

        <h5>v1.0.1 <small>(07/10/2019)</small></h5>
        <ul>
          <li>Added Client Intake Form</li>
          <li>Added Dashboard</li>
          <li>Added Clients Section and Client mangement</li>
          <li>Added Initial Strategies section</li>
        </ul>

        <h5>v1.0.0 <small>(05/23/2019)</small></h5>
        <ul>
          <li><strong>Application:</strong> Initial application code structure</li>
        </ul>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: 'VersionHistory',
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  },
  watch: {

  },
  created () {

  }
}
</script>
